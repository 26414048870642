@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", system-ui, sans-serif;
  }
}

.btn {
  @apply bg-wine text-white font-bold px-5 py-3 rounded-full;
}

.section-title {
  @apply text-center text-wine text-2xl font-semibold md:mb-2 md:text-4xl;
}

.section-subtitle {
  @apply text-center md:text-xl text-gray-700;
}

.mobile-app {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/about-3.jpg");
}
